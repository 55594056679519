.contactForm
{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.8);

}

.contactForm .modal
{
    position: absolute;
    width:30%;
    background-color:rgba(145,200,75);
    left:31%;
    top:0px;
    bottom:0;
    border-radius: 10px;
    color:#fff;
    padding:4%;
    transition: 0.5s;
    padding-top:20px;
    animation: wjazd 1s;
    overflow-y:scroll
    
    
}
.contactForm .modal .bar 
{
 width:100%;
}
.contactForm .modal .bar svg
{
    float:right;
    margin-right:-40px
}
.contactForm .modal .bar svg:hover
{
cursor:pointer;
color:#00b0f0;
}

@keyframes wjazd {
    0%{transform: translateX(1000%);}
    100%{ transform: translateX(0);}
    
}


.contactForm .modal  .form 
{
background-color: rgba(145,200,75,0.6);
border-radius: 10px;;
width:80%;
padding:50px
}
.contactForm .modal  .form  input[type=text]
{
    border:none;
    padding:10px;
    margin:5px;
    width:70%;
    font-size:18px;
}

.contactForm .modal  .form  button
{
    text-align: center;
    width:80%;

    background-color: #91c84b;
    color:#fff;
    padding:15px;
    border: 4px solid #00b0f0;
    margin-top:20px;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size:20px;
}
.contactForm .modal  .form  button:hover
{
    background-color: #00b0f0;
    cursor: pointer;
}

.contactForm  .modal  .form .error
{
    color:red;
    font-size:14px;
    font-weight: bold;
    margin-top:20px;
}
.contactForm  .modal  .form .result
{
    color:white;
    font-size:14px;
    font-weight: bold;
    margin-top:20px;

}
@keyframes spinner {
    0%{transform: rotate(90deg);}
    25%{transform: rotate(180deg);}
    50%{transform: rotate(270deg);}
    75%{transform: rotate(360deg);}
    100%{transform: rotate(90deg);}

}
.contactForm .modal  .form  button svg
{
color:#00b0f0;
height:30px;
transition: 2s;
animation: spinner 1s infinite;
}
.contactForm .modal  .form  .spinnerLoading:hover
{
    background-color: #91c84b;
}

@media (max-width:750px)
{
    .contactForm .modal
{
    width:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
.contactForm .modal .bar svg
{
    float:right;
    margin-right:50px;
    margin-top:50px;
}
}