.whyWe .first
{
    width:60%;
    margin-left:auto;
    margin-right: auto;
    font-size:20px;
    text-align: justify;
}
.whyWe .first h1, .whyWe .first h2
{
    font-size:30px;
    text-align: center;
}

.whyWe .second
{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../photos/secwhy.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
color:#fff;
min-height:300px;
padding-top:100px;
padding-bottom: 100px;;
}
.whyWe .second h2
{
    text-align: center;
    font-size:30px;

}

.whyWe .second p
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:60%;
    text-align: justify;
    font-size:20px;
}
.whyWe .third .element
{
    display: flex;
    min-height:300px

}
#photolokalna
{
    background-image: url('../photos/mapa.png');
    border-radius: 0 10px 10px 0;
}
#photoplgw
{
    background-image: url('../photos/pl.png');

}
#photoekipy
{
    background-image: url('../photos/whyWe.jpeg');

}

#photostart
{
    background-image: url('../photos/2017.png');

}
#photocorab
{
    background-image: url('../photos/corab.png');

}
#photoselfa
{
    background-image: url('../photos/selfa.jpeg');

}
.whyWe .third .element .photo
{
    width:50%;
    min-height:300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;;
    
    
}
.whyWe .third .element .text{

    width:50%;
    height:100%;

}
.whyWe .third .element h2{

    text-align: left;
    font-size:30px;
    padding-left:25px;

}
.whyWe .third .element p{
margin:25px;
font-size:20px;
text-align: justify;
}
.whyWe .third .thirdh2
{
    text-align: center;
}

.whyWe .third .thirdgeneral
{
    display: block;
    width:60%;
    margin-left: auto;
    margin-right: auto;
    font-size:20px;
    text-align: justify;
}

.buttonContact
{
    text-align: center;
    width:20%;
    background-color: #91c84b;
    color:#fff;
    padding:15px;
    border: 4px solid #00b0f0;
    margin-top:20px;
    border-radius: 10px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    font-size:20px;
    margin-top:50px;
    margin-bottom:50px;

}

.buttonContact:hover
{
    background-color: #00b0f0;
    cursor: pointer;
}

.whyWe .kontakt
{
    position: fixed;
    right:0;
    top:20%;
    width:150px;
    background-color: #91c84b;
    padding:15px;
    border-radius:10px;
    font-size:18px;
    font-weight: bold;
    color:#fff;
    transition: 0.2s;
    z-index:1000000000000;
}
.whyWe .kontakt:hover
{
    cursor: pointer;
    background-color: #00b0f0;
}

@media (max-width:750px)
{
    .whyWe .first, .whyWe .second p, .whyWe .third .thirdgeneral
    {
        width:80%;
    }
    .buttonContact
    {
        width:auto;
    }
    .whyWe .third .element
    {
        flex-flow: column;
    }

    .whyWe .third .element .photo
    {
        background-size: contain;
        width:100%;
    }
    .whyWe .third .element .text
    {
        width:90%;
        margin-left:auto;
        margin-right: auto;
    }

    .whyWe .third  .sectext
    {
flex-flow: column-reverse;
    }
}