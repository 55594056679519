.Reviews
{
margin-left:auto;
margin-right:auto;
width:80%;
margin-top:100px;
margin-bottom: 0;

}
.Reviews h1
{
text-align: center;
display: block;
padding:50px;
}

.Reviews .oneReviewWrap
{


}
.Reviews .oneReviewWrap .stars
{
text-align: center;
margin-bottom:40px;
}
.Reviews .oneReviewWrap .stars svg
{
    color: #FDE08D
}
.Reviews .oneReviewWrap .rev
{
    font-size:20px;
    min-height: 170px;
    padding:30px;
}
.Reviews .oneReviewWrap .per
{
    text-align: right;
    font-weight: bold;
}


.Reviews .photoreview
{
    background-image: url('../photos//opinie.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height:350px;
    width:100%;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width:750px)
{
    .Reviews .photoreview
    {
        background-position: 75%;
        background-size:800px
    }
    .Reviews h1
    {
        padding:20px;
    }
}