.Privacy .row
{
    width:70%;
    margin-left: auto;
    margin-right: auto;
}

.Privacy .bar 
{
 width:100%;
}
.Privacy  .bar svg
{
    position:fixed;
    right:70px;
    top:70px;
}
.Privacy  .bar svg:hover
{
cursor:pointer;
color:#00b0f0;
}
.Privacy
{
    position: absolute;
    padding:50px;
    top:20px;
    left:20px;
    right:20px;
    bottom:20px;
    background-color: white;
    overflow-y:scroll;
}