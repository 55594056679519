.colab .colablogos
{
    display: flex;
    justify-content: space-around;
}
.colab h2
{text-align: center;}


@media(max-width:750px)
{.colab .colablogos
{
    flex-flow:column;
}

.colab .colablogos img
{
    width:60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 50px;;
}

}