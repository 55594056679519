.wwh h2
{
    text-align: center;
}

.wwh p
{
    width:60%;
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.wwh  ul li 
{
    display: block;
    list-style-type: none;
    text-align: center;
    border-bottom: white 2px solid;
    background-color: #91c84b;;
    width:200px;
    height:50px;
    line-height: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    color:#fff;
    font-weight: bold;
}

.wwh .skontaktuj
{
    background-color: #91c84b;
    border-radius: 10px;
    min-height:100px;
    width:100%;
    transition: 0.2s;
    color:#fff;
    text-align: center;
    line-height: 100px;
    font-size:22px;
    animation: miga 2s infinite;
}
.wwh .skontaktuj:hover
{
    background-color: #beccab;
    cursor:pointer;
}
@keyframes miga {
    50% {background-color: #91c84b;}
    90% { background-color: #00b0f0;}
}

@media(max-width:750px)
{

    .wwh  ul li 

{
    width:90%;
}
.wwh p
{
    width:90%;
}

}