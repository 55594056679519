.Header
{
    width:100vw;
   min-height:100vh;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(../photos/tlo.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 50px;;
    

}
.Header .miniNav
{
    background-color: rgba(255,255,255,0.7);
    width:30%;
    max-height:100px;
    margin-left: auto;
    margin-right: auto;
padding:30px;
    border-radius:10px;

    padding:20px;
}
.Header .miniNav img
{
    display: block;
    max-height:100%;
    width:80%;
    margin-left: auto;
    margin-right: auto;
}


.Header .modal
{
    display: flex;
align-items: center;
justify-content: space-evenly;
color:#fff;
margin-top:50px;

}
.Header .modal .left
{
width:35%;


}

.Header .modal .left h1
{
    color:#91c84b;
    font-size:30px
}
.Header .modal .left h2
{
    font-size:45px;
}
.Header .modal .left h3
{
    font-size:25px;
}

.Header .modal .right
{
    width:30%;
}
.Header .modal .left .button
{
    text-align: center;
    width:60%;
    text-decoration: none;
    display: block;

    background-color: #91c84b;
    color:#fff;
    padding:15px;
    border: 4px solid #00b0f0;
    margin-top:20px;
    border-radius: 10px;
    
    font-size:20px;
}
.Header .modal .right .formheader
{
background-color: rgba(145,200,75,0.6);
border-radius: 10px;;
width:80%;
padding:50px
}
.Header .modal .right .formheader input[type=text]
{
    border:none;
    padding:10px;
    margin:5px;
    width:70%;
    font-size:18px;
}

.Header .modal .right .formheader button
{
    text-align: center;
    width:80%;

    background-color: #91c84b;
    color:#fff;
    padding:15px;
    border: 4px solid #00b0f0;
    margin-top:20px;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size:20px;
}
.Header .modal .right .formheader button:hover
{
    background-color: #00b0f0;
    cursor: pointer;
}


@media (max-width:750px) {
    .Header
    {
        padding-bottom:100px;
    }
    .Header .miniNav
{
    width:100%;
}
.Header .miniNav img
{
    width:60%;
}
.Header .modal
{
    flex-flow: column;
    justify-content: center;
}
.Header .modal .left
{
    width:80%;
}
.Header .modal .right
{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding:0;
    margin-top:100px;
    
}
.Header .modal .right .formheader
{
    padding:10%;
}

}